<template>
  <div>
    <search-form :items="items" @fnSearch="handleFnSearch" />
    <grid
      :columns="columns"
      :url="url"
      :search="state.search"
      :scroll="{ x: 600, y: gridHeight }"
    />
  </div>
</template>

<script>
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
// import Clustering from '@/api/clustering.js';
import { reactive, watch } from 'vue';
import tagApi from '@/api/tag.js';

export default {
  name: 'user',
  components: {
    SearchForm,
    Grid,
  },
  props: ['tagCode'],
  setup(props) {
    const state = reactive({
      search: { tagCode: props.tagCode },
    });
    watch(
      () => props.tagCode,
      val => {
        props.tagCode && (state.search = { tagCode: val });
      }
    );
    return {
      url: tagApi.pageByTagUrl,
      gridHeight: document.body.clientHeight - 466,
      items: [
        { key: 'name', label: '姓名' },
        { key: 'email', label: '邮箱' },
      ],
      columns: [
        { dataIndex: 'userName', title: '姓名', width: 120 },
        { dataIndex: 'mobileNum', title: '电话' },
        { dataIndex: 'email', title: '邮箱', width: 350 },
      ],
      state,
      handleFnSearch(value) {
        state.search = Object.assign(value, { tagCode: props.tagCode });
      },
    };
  },
};
</script>

<style scoped></style>
