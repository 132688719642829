'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/tag/page',
  //携带签客户 接口
  pageByTagUrl: '/account/contactTag/pageByTag',
  getPage(code, params) {
    return http.get('/account/tagType/page', { params, code });
  },
  getTagTreeList(code, params) {
    return http.get('/account/tag/getTagTree', { params, code });
  },
  getPropertySelector(code) {
    return http.get('/account/tagPageDetail/propertySelector', { code });
  },

  getPropertyRule(code) {
    return http.get('/account/tagPageDetail/ruleDataInfo', { code });
  },

  getActionSelector(code) {
    return http.get('/account/tagPageDetail/actionSelector', { code });
  },

  getTagDetails(code, id) {
    return http.get(`/account/tag/${id}`, { code });
  },
  add(code, data) {
    return http.post('/account/tagType', data, { code });
  },

  // 新增接口
  tagAdd(code, data) {
    return http.post('/account/tag/saveTag', data, { code });
  },
  // 编辑保存接口
  tagUpdate(code, data) {
    return http.put('/account/tag/updateTag', data, { code });
  },

  // 自定义编辑保存接口
  tagCustomiZation(code, data) {
    return http.put('/account/tag/updateTag', data, { code });
  },

  update(code, data) {
    return http.put('/account/tagType', data, { code });
  },

  delete(code, params) {
    return http.delete('/account/tag/deleteTag', { params, code });
  },

  execTag(code, id) {
    return http.get(`/account/ruleExecute/${id}`, { code });
  },

  execTags(code, params) {
    return http.get('account/ruleExecute/tagExecutor', {
      params,
      code,
    });
  },
  // 分群取消执行
  CancelExecTags(code, params) {
    return http.get('account/ruleExecute/tagExecutor', {
      params,
      code,
    });
  },

  // 分类列表查询
  getTagTypeList(code, params) {
    return http.get('/account/tagType/getTagList', {
      params,
      code,
    });
  },

  // 分类列表删除接口
  tagTypeListDelete(code, params) {
    return http.delete('/account/tagType/deleteTagList', {
      params,
      code,
    });
  },

  tagTypeListAdd(code, data) {
    return http.post('/account/tagType/saveTagList', data, { code });
  },

  tagTypeListUpdate(code, data) {
    return http.put('/account/tagType/updateTagList', data, { code });
  },

  getTagSize(code, params) {
    return http.get('/account/tag/getTagSize', {
      params,
      code,
    });
  },
  getCityList(code, params) {
    return http.get(
      '/authority/dictionaryItem/hierarchyBycode?code=CHINA_AREA',
      {
        params,
        code,
      }
    );
  },
  getGroupRulesById(code, id) {
    return http.get(`/account/groupRule/getByConditionId?groupId=${id}`, {
      code,
    });
  },
  getItemTree(code, params) {
    return http.get('/authority/dictionaryItem/code/tree', { params, code });
  },
  updateAutoSwitch(code, { id, autoSwitch }) {
    return http.put(
      `/account/tag/updateAutoSwitch/${id}/${autoSwitch}`,
      {},
      { code }
    );
  },
};
